/*
 *  覆写window.window.XMLHttpRequest, 对ajax请求情况进行上报
 */

/**
 * Rewrite XMLHttpRequest prototype function
 * Example: `network(this.cacheUploader, this._state, opts)`
 * @param {Function} function to upload log data
 * @param {Object} object to logger state
 * @param {Object} logger options
 * Business logic including prelogid, traceid, ajax success log and ajax failed log
 */
export function network (uploader, state, opts) {
  /*
   * 拦截ajax请求，保存data
   *
   */
  var mockAjax = function () {
    // 放弃低版本IE
    if (!window.XMLHttpRequest) {
      return;
    }

    // 收集完信息 _fun.apply(this,arguments)防污染原型
    var _open = window.XMLHttpRequest.prototype.open;

    window.XMLHttpRequest.prototype.open = function (method, url, boolen) {
      var self = this;
      var args = [].slice.call(arguments);
      var _onreadystatechange = self.onreadystatechange || function () {};

      var item = {
        url: url || '',
        method: method || ''
      };

      self.addEventListener('readystatechange', function () {
        item.url = args[1];

        if (self.readyState === 0) {
          // UNSENT
        } else if (self.readyState === 1) {
          // OPEND
          item.filter = true;
          // 是否过滤接口上报，如果比如localhost:7001/v1/interface的接口不上报，则配置localhost即可。
          var ignoreIndex = opts.ignoreAjaxFilter.findIndex((filter) => {
            return item.url.indexOf(filter) !== -1;
          });
          // var traceIndex = opts.traceFilter.findIndex((filter) => {
          //   return item.url.indexOf(filter) !== -1
          // })

          if (item.url && (typeof item.url === 'string' || item.url instanceof String) && (item.url.indexOf(opts.baseURL) === -1) && ignoreIndex === -1) {
            item.filter = false;
            item.startTime = +new Date();
            item.traceid = (state.pvid || '') + '-' + state.ajaxid;
            state.ajaxid++;
            if (opts.trace.open) {
              self.setRequestHeader(
                'traceid',
                item.traceid
              );
              self.setRequestHeader('rpcid', '1');
            }
          }
        } else if (self.readyState === 2) {
          // HEADERS_RECEIVED
        } else if (self.readyState === 3) {
          // LOADING
        } else if (self.readyState === 4) {
          // DONE
          if (
            Math.random() < opts.network.sample &&
            !item.filter
          ) {
            var sData;
            item.endTime = +new Date();
            item.time = item.endTime - (item.startTime || 0);
            item.status = self.status;
            item.statusText = self.statusText;
            item.response =
              self.response.length > 2000
                ? self.response.substr(0, 2000)
                : self.response;

            if (
              (self.status >= 200 && self.status < 300) ||
              self.status === 304
            ) {
              sData = {
                elapsedTime: item.time,
                method: item.method,
                resourceUrl: item.url,
                response: item.response,
                status: item.status,
                statusText: item.statusText,
                type: 'Performance-Ajax',
                traceid: item.traceid
              };
              uploader(sData, 'c', 'network');
            } else {
              sData = {
                elapsedTime: item.time,
                method: item.method,
                resourceUrl: item.url,
                response: item.response,
                status: item.status,
                statusText: item.statusText,
                type: 'Ajax',
                traceid: item.traceid
              };
              uploader(sData, 'c', 'network');
            }
          }
          if (!item.filter && opts.trace.open) {
            var aData = {
              type: 'ajaxTraceLog',
              traceid: item.traceid,
              rpcid: '1',
              ajaxUrl: item.url
            }
            uploader(aData, 'c', 'network');
          }
        }
        return _onreadystatechange.apply(self, arguments);
      })

      return _open.apply(self, arguments);
    };
  };

  mockAjax();
}
