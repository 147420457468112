import XesLoggerSDK from '../index'
import TraceKit from '../../vendor/tracekit'

/**
 * Format Vue component name from vm
 * Example: `metaData.componentName = formatComponentName(vm)`
 *
 * @param {Object} object to Vue instance vm
 */
function formatComponentName (vm) {
  if (vm.$root === vm) {
    return 'root instance';
  }
  var name = vm._isVue ? vm.$options.name || vm.$options._componentTag : vm.name;
  return (
    (name ? 'component <' + name + '>' : 'anonymous component') +
    (vm._isVue && vm.$options.__file ? ' at ' + vm.$options.__file : '')
  );
}

/**
 * Custom Vue error handle function
 * Example: `vueException(this.cacheUploader)`
 *
 * @param {Object} object to log uploader
 */
export function vueException (uploader, vue) {
  var Vue = window.Vue || vue;

  if (!Vue || !Vue.config) return;

  var _oldOnError = Vue.config.errorHandler;

  Vue.config.errorHandler = function (err, vm, info) {
    var metaData = {};
    if (Object.prototype.toString.call(vm) === '[object Object]') {
      metaData.componentName = formatComponentName(vm);
      metaData.propsData = vm.$options.propsData;
    }

    if (typeof info !== 'undefined') {
      metaData.lifecycleHook = info;
    }

    var errorData = TraceKit.computeStackTrace(err);
    errorData.type = 'Script';
    var el = errorData.stack[0];
    errorData.url = el.url || '';
    errorData.line = el.line || '';
    errorData.col = el.column || '';
    errorData.fileName = el.url || '';
    errorData.msg = errorData.message || '';
    errorData.vue = metaData;
    delete errorData['message'];

    if (
      errorData.msg &&
      (typeof errorData.msg === 'string' || errorData.msg instanceof String) &&
      errorData.msg.indexOf('Script error') === -1
    ) {
      uploader(errorData, 'c', 'js');
    }

    if (typeof _oldOnError === 'function') {
      _oldOnError.call(this, err, vm, info);
    }

    console.log(err);
  };
}

/**
 * Vue plugin install function
 * Example: `XesLoggerSDK.install = vuePluginInstall`
 *
 * @param {Object} object to Vue instance
 * @param {Object} object to Vue plugin options
 */
export function vuePluginInstall (Vue, options) {
  var xesLogger = new XesLoggerSDK(options, Vue)
  const isVue2 = Vue.version.split('.')[0] === '2'
  const isVue3 = Vue.version.split('.')[0] === '3'
  if (isVue2) {
    if (!Vue.prototype.$xesLogger) {
      Vue.prototype.$xesLogger = xesLogger
    }
  }
  // Vue3
  if (isVue3) {
    if (!Vue.config.globalProperties.$xesLogger) {
      Vue.config.globalProperties.$xesLogger = xesLogger;
    }
  }
}
