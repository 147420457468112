/**
 * Attach resource load exception function
 * Example: `resException(this.cacheUploader)`
 * @param {Function} function to upload log data
 * @param {Object} object to logger opts
 */
export function exception (ctx, opts) {
  ctx.resourceErrorHandler = (evt, errorData = {}) => {
    // 目前只统计link、script和img三种资源错误
    if (evt.target.tagName) {
      // 记录页面错误元素
      var target = evt.target ? evt.target : evt.srcElement;
      errorData.outerHTML = (target && target.outerHTML).slice(0, 200);
      // 记录错误时间戳
      errorData.timestamp = evt.timeStamp || '';
      // 记录XPath资源错误定位符
      errorData.XPath = (function (target) {
        var arr = [];
        for (
          ;
          target && target.nodeType === window.Node.ELEMENT_NODE;
          target = target.parentNode
        ) {
          var pre;
          var index = 0;
          var bool = false;
          for (pre = target.previousSibling; pre; pre = pre.previousSibling) {
            pre.nodeType !== window.Node.DOCUMENT_TYPE_NODE &&
              pre.nodeName === target.nodeName &&
              ++index;
          }
          for (pre = target.nextSibling; pre && !bool; pre = pre.nextSibling) {
            pre.nodeName === target.nodeName && (bool = true);
          }
          var left =
            (target.prefix ? target.prefix + ':' : '') + target.localName;
          var right = index || bool ? '[' + (index + 1) + ']' : '';
          arr.splice(0, 0, left + right);
        }
        return arr.length ? '/' + arr.join('/') : null;
      })(target);
      // 记录Selector资源错误定位符
      errorData.selector = (function (target) {
        var arr = [];
        for (; target.parentNode;) {
          if (target.id) {
            arr.unshift('#' + target.id);
            break;
          }
          if (target === target.ownerDocument.documentElement) {
            arr.unshift(target.tagName);
          } else {
            var i = 1;
            for (
              var el = target;
              el.previousElementSibling;
              el = el.previousElementSibling, i++
            );
            arr.unshift(target.tagName + ':nth-child(' + i + ')');
          }
          target = target.parentNode;
        }
        return arr.join(' > ');
      })(target);
      errorData.type = 'Resource';
      errorData.url = (evt.target && evt.target.src) || evt.target.href || '';
      errorData.baseURI = (evt.target && evt.target.baseURI) || '';
      errorData.tagName = (evt.target && evt.target.tagName) || '';
      if (
        errorData.url &&
        (typeof errorData.url === 'string' ||
          errorData.url instanceof String) &&
        errorData.url.indexOf(opts.baseURL) !== 0
      ) {
        ctx.cacheUploader(errorData, 'c', 'res');
      }
    }
  }

  if (window.__sourceError && window.__sourceError.constructor === Array) {
    for (var i = 0; i < window.__sourceError.length; i++) {
      ctx.resourceErrorHandler(window.__sourceError[i]);
    }
  }

  if (window.addEventListener) {
    window.addEventListener('error', event => ctx.resourceErrorHandler(event), true);
  }
}
