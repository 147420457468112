/**
 * Get cookie value from key
 * Example: `var logid = getCookieValue('prelogid') || ''`
 * @param {String} key of cookie
 * @return {String} cookie value
 */
export function getCookieValue (name) {
  if (document.cookie.length > 0) {
    var start = document.cookie.indexOf(name + '=');
    if (start !== -1) {
      start = start + name.length + 1;
      var end = document.cookie.indexOf(';', start);
      if (end === -1) {
        end = document.cookie.length;
      }
      return unescape(document.cookie.substring(start, end));
    }
  }
  return '';
}

/**
 * Set cookie value of key
 * Example: `setCookieValue('prelogid', getCookieValue('X-Request-Id') || '');`
 * @param {String} key of cookie
 * @param {String} value of cookie
 */
export function setCookieValue (name, value) {
  var exdate = new Date();
  var expiredays = 365;
  exdate.setDate(exdate.getDate() + expiredays);
  window.document.cookie =
    name +
    '=' +
    escape(value) +
    (expiredays == null ? '' : ';expires=' + exdate.toGMTString()) +
    '; path=/' +
    '; domain=xueersi.com';
  window.document.cookie =
    name +
    '=' +
    escape(value) +
    (expiredays == null ? '' : ';expires=' + exdate.toGMTString()) +
    '; path=/' +
    '; domain=dahai.com';
  window.document.cookie =
    name +
    '=' +
    escape(value) +
    (expiredays == null ? '' : ';expires=' + exdate.toGMTString()) +
    '; path=/' +
    '; domain=xesv5.com';
  window.document.cookie =
    name +
    '=' +
    escape(value) +
    (expiredays == null ? '' : ';expires=' + exdate.toGMTString()) +
    '; path=/' +
    '; domain=xiwang.com';
  window.document.cookie =
    name +
    '=' +
    escape(value) +
    (expiredays == null ? '' : ';expires=' + exdate.toGMTString()) +
    '; path=/' +
    '; domain=jxxes.com';
}
